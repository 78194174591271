import {
  HeadingXLarge,
  HeadingXSmall,
  ParagraphLarge,
} from "baseui/typography";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { TextButton, KIND, SIZE } from "../TextButton";
import {
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { footerPopupClose } from "../../redux/Invoice/action";
import { FormattedNumber, useIntl } from "react-intl";
import { LabelLarge } from "baseui/typography";
import { NumericFormat } from "react-number-format";
import { paymentAmount } from "../../redux/Layout/action";
import { currencyList } from "../../utils/Currency/CurrencyToSymbol";
import { useQuery } from "react-query";
import { getCallBackLater } from "../../services/customerPortal";
import _ from "lodash";

const TableSelectedPhoneFooter = ({ setMobFooter, mobFooter }) => {
  let dispatch = useDispatch();
  const { selectedInvoice } = useSelector((s) => s.invoiceReducer);
  const { default_color } = useSelector((s) => s.layoutReduceData);
  let accessValue = useSelector((e) => e.layoutReduceData.accessData);
  const location = useLocation();
  const hash = location.pathname.split("/")[1];
  const [addSearchParams, setAddSearchParams] = useSearchParams();
  let showActiveUser = addSearchParams.get("i");

  let TotalAmount = selectedInvoice.reduce((acc, curr) => {
    if (!isNaN(curr.invoiceTotalAmount)) {
      return Number(acc) + Number(curr.invoiceTotalAmount);
    }
    // return acc.invoiceTotalAmount;
  }, 0);

  let currentDefaultFormatDetails = useSelector(
    (e) => e.layoutReduceData?.organization?.amount_format
  );

  const requestTimeData = useQuery(
    [`PROMISE-TO-PAY`],
    async () => {
      return await getCallBackLater({ entity: "PROMISE_TO_PAY" });
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  let navigate = useNavigate();
  const {
    default_nav_color,
    organization,
    customer,
    tabMotion,
    referenceData,
  } = useSelector((s) => s.layoutReduceData);

  let currencyGroupStyle =
    currentDefaultFormatDetails && currentDefaultFormatDetails == "lakhs"
      ? "lakh"
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_cs_dd"
      ? "thousand"
      : "thousand";

  let currencySeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? " "
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? "."
      : ",";

  let currencyDecimalSeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? ","
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? ","
      : ".";
  const intl = useIntl();
  return (
    <div
      style={{
        height: "120px",
        width: "100%",
        backgroundColor: "#ffffff",
        // position: "absolute",
        // bottom: "0%",
        borderTopLeftRadius: "20px",
        borderTopRightRadius: "20px",
        border: "0.5px solid #cdced9",
        boxShadow: "0px -1px 5px rgba(51, 56, 96, 0.2)",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          padding: "20px",
          //   alignContent: "center",
          //   justifyContent: "center",
        }}
      >
        {/* <HeadingXLarge style={{ color: "#08083A" }}>Summary</HeadingXLarge> */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            justifyContent: "space-between",
          }}
        >
          <ParagraphLarge style={{ color: "#08083A" }}>
            {selectedInvoice && selectedInvoice.length}{" "}
            {intl.formatMessage({ id: "invoice_numbers" })}
            {selectedInvoice && selectedInvoice.length <= 1 ? "" : "s"}{" "}
          </ParagraphLarge>
          <div
            style={{
              height: "6px",
              width: "6px",
              backgroundColor: "#ffffff",
              borderRadius: "50%",
            }}
          ></div>
          <div style={{ display: "flex", gap: "3px" }}>
            {/* <sup style={{ fontSize: "11px", color: "#08083A" }}>
              {selectedInvoice &&
                selectedInvoice[0] &&
                selectedInvoice[0].invoiceCurrency}
            </sup>
            <ParagraphLarge style={{ color: "#08083A" }}>
              <FormattedNumber value={TotalAmount} />{" "}
            </ParagraphLarge> */}
            <NumericFormat
              displayType="text"
              value={TotalAmount}
              thousandsGroupStyle={currencyGroupStyle}
              decimalScale={2}
              fixedDecimalScale={true}
              thousandSeparator={currencySeparator}
              decimalSeparator={currencyDecimalSeparator}
              renderText={(values) => (
                <>
                  <LabelLarge $style={{ color: "#333860" }}>
                    {currencyList[
                      selectedInvoice &&
                        selectedInvoice[0] &&
                        selectedInvoice[0].invoiceCurrency
                    ]
                      ? currencyList[
                          selectedInvoice &&
                            selectedInvoice[0] &&
                            selectedInvoice[0].invoiceCurrency
                        ]
                      : selectedInvoice &&
                        selectedInvoice[0] &&
                        selectedInvoice[0].invoiceCurrency}{" "}
                    {values}
                  </LabelLarge>
                </>
              )}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            gap: "5px",
          }}
        >
          {/* <div
            style={{ width: "100%" }}
            onClick={() => {
              // setMobFooter(!mobFooter);
              dispatch(footerPopupClose(true));
            }}
          > */}
          {/* <NavLink
              to={`/${hash}/payments${
                showActiveUser ? `?i=${showActiveUser}` : ""
              }`}
            > */}
          <TextButton
            kind={KIND.primary}
            size={SIZE.compact}
            fullWidth
            // style={{
            //   height: "48px",
            // }}
            onClick={() => {
              dispatch(paymentAmount(TotalAmount));
              navigate(
                `/${hash}/payments${
                  showActiveUser ? `?i=${showActiveUser}` : ""
                }`
              );
            }}
          >
            <span
              style={{
                // mixBlendMode: "difference",
                color: "#ffffff",
                fontSize: "13px",
              }}
            >
              Pay Now{" "}
              {Number(_.get(customer, "pay_disc_pert", 0)) > 0
                ? `& Save ${Number(_.get(customer, "pay_disc_pert", 0))}%`
                : ""}
            </span>{" "}
          </TextButton>
          {accessValue?.is_request_payment_plan ||
          accessValue?.is_pre_defined_payment_plan ? (
            <div
              className="pay_installment_btn"
              style={{
                color: default_color,
                border: `1px solid ${default_color}`,
              }}
              onClick={() => {
                navigate(
                  `/${hash}/requesting-times${
                    showActiveUser ? `?i=${showActiveUser}` : ""
                  }`
                );
              }}
            >
              {requestTimeData?.data?.data?.doc?.is_payment_plan_pending
                ? "View Installments"
                : requestTimeData?.data?.data?.doc?.is_promise_to_pay_pending
                ? "View Promise"
                : requestTimeData?.data?.data?.doc?.is_offer
                ? "View Offers"
                : "Payment Options"}
            </div>
          ) : accessValue?.is_request_back ? (
            <div
              className="pay_installment_btn"
              style={{
                color: default_color,
                border: `1px solid ${default_color}`,
              }}
              onClick={() => {
                navigate(
                  `/${hash}/requesting-times${
                    showActiveUser ? `?i=${showActiveUser}` : ""
                  }`
                );
              }}
            >
              {requestTimeData?.data?.data?.doc?.is_payment_plan_pending
                ? "View Installments"
                : requestTimeData?.data?.data?.doc?.is_promise_to_pay_pending
                ? "View Promise"
                : "Pay Later"}
            </div>
          ) : (
            ""
          )}
          {/* {accessValue && accessValue.is_request_back && (
            <div
              className="pay_installment_btn"
              style={{
                color: default_color,
                border: `1px solid ${default_color}`,
              }}
              onClick={() => {
                navigate(
                  `/${hash}/requesting-times${
                    showActiveUser ? `?i=${showActiveUser}` : ""
                  }`
                );
              }}
            >
              Pay in Installments
            </div>
          )} */}
          {/* </NavLink> */}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default TableSelectedPhoneFooter;
