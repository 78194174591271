import { HeadingXSmall, ParagraphLarge } from "baseui/typography";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextButton, KIND, SIZE } from "../TextButton";
import {
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { FormattedNumber, useIntl } from "react-intl";
import { NumericFormat } from "react-number-format";
import { LabelLarge } from "baseui/typography";
import { paymentAmount } from "../../redux/Layout/action";
import { currencyList } from "../../utils/Currency/CurrencyToSymbol";
import { getCallBackLater } from "../../services/customerPortal";
import { useQuery } from "react-query";
import _ from "lodash";

const TableSelectedFooter = ({ toggleAllRowsSelected }) => {
  const { default_color } = useSelector((s) => s.layoutReduceData);
  const { selectedInvoice } = useSelector((s) => s.invoiceReducer);
  let accessValue = useSelector((e) => e.layoutReduceData.accessData);
  const location = useLocation();
  const hash = location.pathname.split("/")[1];
  const [addSearchParams, setAddSearchParams] = useSearchParams();
  let showActiveUser = addSearchParams.get("i");

  const requestTimeData = useQuery(
    [`PROMISE-TO-PAY`],
    async () => {
      return await getCallBackLater({ entity: "PROMISE_TO_PAY" });
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  let dispatch = useDispatch();
  let navigate = useNavigate();

  let TotalAmount = selectedInvoice.reduce((acc, curr) => {
    if (!isNaN(curr.invoiceTotalAmount)) {
      return Number(acc) + Number(curr.invoiceTotalAmount);
    }
    // return acc.invoiceTotalAmount;
  }, 0);

  let currentDefaultFormatDetails = useSelector(
    (e) => e.layoutReduceData?.organization?.amount_format
  );
  const {
    default_nav_color,
    organization,
    customer,
    tabMotion,
    referenceData,
  } = useSelector((s) => s.layoutReduceData);
  let currencyGroupStyle =
    currentDefaultFormatDetails && currentDefaultFormatDetails == "lakhs"
      ? "lakh"
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_cs_dd"
      ? "thousand"
      : "thousand";

  let currencySeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? " "
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? "."
      : ",";

  let currencyDecimalSeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? ","
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? ","
      : ".";
  const intl = useIntl();
  return (
    <div
      style={{
        // position: 'fixed',
        bottom: " 0 %",
        width: "100 %",
        height: "auto",
        padding: "3 % 0px",
        borderRadius: "10px",
        borderTop: " 1px solid #CDCED9",
        height: "70px",
        width: "100%",
        backgroundColor: default_color,
        // position: "absolute",
        // bottom: "0%",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "14px",
          paddingRight: "20px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <ParagraphLarge style={{ color: "#ffffff" }}>
            {selectedInvoice && selectedInvoice.length}{" "}
            {intl.formatMessage({ id: "invoice_numbers" })}
            {selectedInvoice && selectedInvoice.length <= 1 ? "" : "s"}{" "}
          </ParagraphLarge>
          <div
            style={{
              height: "6px",
              width: "6px",
              backgroundColor: "#ffffff",
              borderRadius: "50%",
            }}
          ></div>
          <div style={{ display: "flex", gap: "3px" }}>
            {/* <sup style={{ fontSize: "11px", color: "#ffffff" }}>
              {selectedInvoice &&
                selectedInvoice[0] &&
                selectedInvoice[0].invoiceCurrency}
            </sup>
            <ParagraphLarge style={{ color: "#ffffff" }}>
              {TotalAmount}
              <FormattedNumber value={TotalAmount} />{" "}
            </ParagraphLarge> */}
            <NumericFormat
              displayType="text"
              value={TotalAmount}
              thousandsGroupStyle={currencyGroupStyle}
              decimalScale={2}
              fixedDecimalScale={true}
              thousandSeparator={currencySeparator}
              decimalSeparator={currencyDecimalSeparator}
              renderText={(values) => (
                <>
                  <LabelLarge style={{ color: "#ffffff", fontWeight: "500" }}>
                    {currencyList[
                      selectedInvoice &&
                        selectedInvoice[0] &&
                        selectedInvoice[0].invoiceCurrency
                    ]
                      ? currencyList[
                          selectedInvoice &&
                            selectedInvoice[0] &&
                            selectedInvoice[0].invoiceCurrency
                        ]
                      : selectedInvoice &&
                        selectedInvoice[0] &&
                        selectedInvoice[0].invoiceCurrency}
                    {values}
                  </LabelLarge>
                </>
              )}
            />
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <div>
            {accessValue?.is_request_payment_plan ||
            accessValue?.is_pre_defined_payment_plan ? (
              <div
                className="pay_installment_btn"
                onClick={() => {
                  navigate(
                    `/${hash}/requesting-times${
                      showActiveUser ? `?i=${showActiveUser}` : ""
                    }`
                  );
                }}
              >
                {requestTimeData?.data?.data?.doc?.is_payment_plan_pending
                  ? "View Installments"
                  : requestTimeData?.data?.data?.doc?.is_promise_to_pay_pending
                  ? "View Promise"
                  : requestTimeData?.data?.data?.doc?.is_offer
                  ? "View Offers"
                  : "Payment Options"}
              </div>
            ) : accessValue?.is_request_back ? (
              <div
                className="pay_installment_btn"
                onClick={() => {
                  navigate(
                    `/${hash}/requesting-times${
                      showActiveUser ? `?i=${showActiveUser}` : ""
                    }`
                  );
                }}
              >
                {requestTimeData?.data?.data?.doc?.is_payment_plan_pending
                  ? "View Installments"
                  : requestTimeData?.data?.data?.doc?.is_promise_to_pay_pending
                  ? "View Promise"
                  : "Pay Later"}
              </div>
            ) : (
              ""
            )}
          </div>
          <div style={{ width: "auto" }}>
            <TextButton
              kind={KIND.secondary}
              size={SIZE.compact}
              fullWidth
              onClick={() => {
                dispatch(paymentAmount(TotalAmount));
                navigate(
                  `/${hash}/payments${
                    showActiveUser ? `?i=${showActiveUser}` : ""
                  }`
                );
              }}
            >
              Pay Now{" "}
              {Number(_.get(customer, "pay_disc_pert", 0)) > 0
                ? `& Save ${Number(_.get(customer, "pay_disc_pert", 0))}%`
                : ""}
            </TextButton>
            {/* </NavLink> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableSelectedFooter;
