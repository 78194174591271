import { LabelLarge, ParagraphMedium, ParagraphLarge } from "baseui/typography";
import React, { useState, useMemo, useEffect, useRef } from "react";
import TickSvg from "../../assets/img/svg/TickSvg";
import { Link, useParams } from "react-router-dom";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { KIND } from "baseui/button";
import { Tabs, Tab } from "baseui/tabs-motion";
import { useForm, Controller } from "react-hook-form";
import { Input } from "baseui/input";
import DateRangeSelect from "../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import {
  useTable,
  useSortBy,
  useRowSelect,
  useFlexLayout,
  useResizeColumns,
  useExpanded,
  useFilters,
  useColumnOrder,
  usePagination,
} from "react-table";

import MOCK_DATA from "./MOCK_DATA.json";
import { COLUMNS } from "./columns";
import { SIZE } from "baseui/button";
import MessageHeaderSvg from "../../assets/img/svg/MessageHeaderSvg";
import axios from "axios";
import { getGridStructure } from "../../services/gridStructure";
import columnMapper from "../../utils/mapper";
import { useQuery } from "react-query";
import { getInvoice, popUp } from "../../services/customerPortal";
import ChipFilter from "../../components/ChipFilter";
import { useSelector } from "react-redux";
import SidebarMobileView from "../../layouts/SideBar/SidebarMobileView";
import {
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { IconButton } from "../../components/IconButton";
import { Avatar } from "baseui/avatar";
import { useIntl } from "react-intl";
import InvoiceTable from "./InvoiceTable";
import { useDispatch } from "react-redux";
import { getSelectedInvoice } from "../../redux/Invoice/action";
import { NumericFormat } from "react-number-format";
import { setESignModal, tabMotionChange } from "../../redux/Layout/action";
import CustomerPortalModal from "../../components/PortalModal/PortalModal";
import { TextButton } from "../../components/TextButton";
import { Modal } from "baseui/modal";
import { currencyList } from "../../utils/Currency/CurrencyToSymbol";
import { setDebtorRightsModal } from "../../redux/Layout/action";
import DebtorRight from "../../assets/img/svg/DebtorRight";
import EditSvg from "../../assets/img/svg/EditSvg";
import CrosssSvg from "../../assets/img/svg/CrossSvg";
import _ from "lodash";
import moment from "moment";
import useFormat from "../../utils/useFormat/useFormat";
import ESignModal from "../../components/ESignModal/ESignModal";
const DetailsComponent = ({ keyValue, value, valueColor, cellType }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "baseline",
        justifyContent: "space-between",
      }}
    >
      <ParagraphLarge
        style={{
          color: "#787878",
          textAlign: "start",
          fontSize: "14px",
          lineHeight: "17px",
        }}
      >
        {" "}
        {keyValue ? keyValue : ""}
        {keyValue ? ":" : ""}
      </ParagraphLarge>

      <ParagraphLarge
        style={{
          color: valueColor ? valueColor : "#333860",
          width: "180px",
          fontWeight: "500",
          textAlign: "end",
          fontSize: "14px",
          // lineHeight: "17px",
        }}
      >
        {value ? value : ""}
      </ParagraphLarge>
    </div>
  );
};

const Invoices = ({ setFooter, isFooter }) => {
  const [isInvoiceDetails, setInvoiceDetails] = useState({});
  const format = useFormat();
  const { id } = useParams();
  const [activeKey, setActiveKey] = React.useState("0");
  // const [columnData, setColumnData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const expandedRowId = useRef(null);
  const [addSearchParams, setAddSearchParams] = useSearchParams();
  let showActiveUser = addSearchParams.get("i");
  const [queryFilter, setQueryFilter] = useState({
    invoice_number: showActiveUser ? showActiveUser : "",
    type: [],
  });
  const [width, setWidth] = useState(800);
  let is_document_authorize_configured = useSelector(
    (e) => e.layoutReduceData.is_document_authorize_configured
  );
  let dispatch = useDispatch();

  const [dateValue, setDateValue] = React.useState([]);
  const [querySortBy, setQuerySortBy] = useState("status");
  let customerData = useSelector((e) => e.layoutReduceData?.customer);
  let orgName = useSelector((e) => e.layoutReduceData?.organization.name);
  let organizationData = useSelector((e) => e.layoutReduceData.organization);
  let { invoice } = useSelector((e) => e.layoutReduceData);
  let accessValue = useSelector((e) => e.layoutReduceData.accessData);
  const {
    default_nav_color,
    organization,
    customer,
    tabMotion,
    referenceData,
    isEsign,
    isEsignStore,
  } = useSelector((s) => s.layoutReduceData);
  let intl = useIntl();
  const {
    default_color,
    customer: { total_outstanding_amount = 0, currency = "" },
    debtor_rights_modal,
  } = useSelector((s) => s.layoutReduceData);

  const { selectedInvoice } = useSelector((s) => s.invoiceReducer);
  const currentDefaultFormatDetails = useSelector(
    (e) => e.layoutReduceData?.organization?.amount_format
  );

  const { modalState } = useSelector((e) => e.layoutReduceData);

  let RowInvoiceNumber = _.get(isInvoiceDetails, "invoice_number", "");
  let clientRef = _.get(isInvoiceDetails, "client_reference", "");
  let customerId = _.get(isInvoiceDetails, "cus.customer_id", "");

  let currencyGroupStyle =
    currentDefaultFormatDetails && currentDefaultFormatDetails == "lakhs"
      ? "lakh"
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_cs_dd"
      ? "thousand"
      : "thousand";

  let currencySeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? " "
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? "."
      : ",";

  let currencyDecimalSeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? ","
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? ","
      : ".";

  const customizedData = useSelector((state) => state?.customizeReducer?.data);

  function sendEmail() {
    if (_.get(customizedData, "em_conf", "")) {
      window.location.assign(`mailto:${_.get(customizedData, "em_conf", "")}`);
    } else {
      window.location.assign(
        `mailto:${organizationData && organizationData.email}`
      );
    }
  }

  const location = useLocation();

  const hash = location?.pathname?.split("/")[1];

  const menuName =
    activeKey === "0"
      ? "customer_portal_custom_inv"
      : "paid_invoice_customer_portal";

  //Overall Grid In these grid Structure We have all Ivoice keys expect the Custome Fields

  const gridForOverAllInvoice = useQuery(
    `GRIDSTRUCTURE_${menuName}`,
    async () => {
      return await getGridStructure({
        menu: menuName,
      });
    }
  );

  // Utility function to get custom fields for the Category Invoice
  const getCustomInvoiceFields = (organization) => {
    const allowedCategories = ["INVOICE", "CUSTOMER"];
    return _.get(organization, "custom_field_list", []).filter((item) =>
      allowedCategories.includes(item.category)
    );
  };

  // Utility function to get enabled data fields from organization Customize Labels
  const getEnabledDateFields = (organization) => {
    return _.get(organization, "customer_portal_ui.cuslb", []).filter(
      (inv) => inv?.ise === true
    );
  };

  //Based on the Type make the cell Type for Custom Fields
  const dataType = {
    AMOUNT: "CURRENCY",
    DATE: "DATE",
    HYPERLINK: "HYPERLINK",
    TEXT: "ALPHANUMERIC",
    LONG_TEXT: "ALPHANUMERIC",
    NUMBER: "ALPHANUMERIC",
  };

  // Function to create custom field structure
  const createCustomFieldStructure = (
    customInvoiceFields,
    literalAddedData,
    isEnabledData
  ) => {
    const compareInvoiceAllData = isEnabledData
      ? isEnabledData.map((item) => item.syt)
      : [];
    const comparedDataCustomFields = customInvoiceFields
      ? customInvoiceFields.filter((item) =>
          compareInvoiceAllData.includes(item.path)
        )
      : [];

    return comparedDataCustomFields.map((field, idx) => {
      const { path, name, data_type } = field;
      const literalId = literalAddedData[path] || name;
      return {
        accessor: `custom_field.${path}`,
        alignment: "left",
        cell_type: dataType[data_type] || "ALPHANUMERIC",
        col_id: `col_cf_${idx}`,
        filter_by: false,
        header_alignment: "left",
        is_hidden: false,
        literal_id: literalId,
        order: 0,
        sort_by: false,
        type: "STRING",
        width: 150,
      };
    });
  };
  //UseEffect for Handling create Grid for the Customize Labels in customer Invoice Summary
  useEffect(() => {
    // Add the Condition grid had data & menu not be paid invoice
    if (
      gridForOverAllInvoice.data &&
      menuName !== "paid_invoice_customer_portal"
    ) {
      const customInvoiceFields = getCustomInvoiceFields(organization);
      const isEnabledData = getEnabledDateFields(organization);

      const compareDateGRCFKeys = isEnabledData.map((item) => item.syt);
      //Filter the grid based on the Accessor which is same as Syt
      const filteredComparedData = _.get(
        gridForOverAllInvoice,
        "data.data.doc.table",
        []
      ).filter((item) => compareDateGRCFKeys?.includes(item.accessor));
      //separted the portt from the actual Data
      const literalAddedData = isEnabledData?.reduce((acc, item) => {
        acc[item.syt] = item?.portt;
        return acc;
      }, {});

      // Update literal_id in filteredComparedData based on literalAddedData
      const updatedFilteredComparedData = filteredComparedData.map((item) => {
        const newLiteralId = literalAddedData[item.accessor];
        return newLiteralId ? { ...item, literal_id: newLiteralId } : item;
      });
      //usig the utill function createCustomField
      const customFieldStructure = createCustomFieldStructure(
        customInvoiceFields,
        literalAddedData,
        isEnabledData
      );
      //merge the custom and actual filter grid
      let mergedInvoicegrid = [
        ...updatedFilteredComparedData,
        ...customFieldStructure,
      ];
      //Based on the indx of the actual data need to pass value  the order  of th grid
      const isEnabledDataMap = isEnabledData.reduce((acc, item) => {
        acc[item.syt] = item.indx;
        return acc;
      }, {});

      const updatedMergedInvoiceGrid = mergedInvoicegrid
        ? mergedInvoicegrid.map((item) => {
            const newOrder =
              isEnabledDataMap[
                item.accessor.startsWith("custom_field.")
                  ? item.accessor.replace("custom_field.", "")
                  : item.accessor
              ];
            return newOrder !== undefined ? { ...item, order: newOrder } : item;
          })
        : [];
      // Here I make the grid filter and sort false
      // Here I Hard code the nested Grid specially for the accessor field invoice_date
      const updatedColumnData = updatedMergedInvoiceGrid.map((item) => {
        if (item.accessor === "invoice_date") {
          return {
            ...item,
            is_hidden: false,
            filter_by: false,
            sort_by: false,
            nested: [
              {
                col_id: "col_cs_27",
                literal_id: "invoice_date",
                accessor: "invoice_date",
                type: "DATE",
                cell_type: "DATEORGTZ",
                filter_by: true,
                alignment: "center",
              },
              {
                col_id: "col_cs_28",
                literal_id: "due_days",
                accessor: "inv_due_days",
                type: "DAY_DIFFERENCE",
                cell_type: "DAY_DIFFERENCE",
                filter_by: true,
                alignment: "center",
              },
            ],
          };
        }
        return {
          ...item,
          is_hidden: false,
          filter_by: false,
          sort_by: false,
        };
      });
      setDateValue(updatedColumnData);
    }
    //else Statement is basically for the paid Invoies Grid
    else if (_.get(gridForOverAllInvoice, "data.data.doc.table", [])) {
      const updatedGrid = _.get(
        gridForOverAllInvoice,
        "data.data.doc.table",
        []
      )
        .map((item) => {
          const xItem = _.get(
            organization,
            "customer_portal_ui.cuslb",
            []
          )?.find((xItem) => xItem.syt === item.accessor);
          return {
            ...item,
            portt: xItem?.portt ? xItem?.portt : "",
            ise: xItem?.ise ? xItem?.ise : "",
          };
        })
        .filter((e) => e.ise === true);
      setDateValue(updatedGrid);
    }
  }, [gridForOverAllInvoice.data, organization]);

  const columnData = useMemo(() => {
    return dateValue;
  }, [dateValue]);

  const { data, isFetched, isError, isLoading, isFetching } = useQuery(
    [
      `INVOICES`,
      {
        filters: queryFilter,
        sort: querySortBy,
      },
    ],
    async ({ queryKey }) => {
      let { sort, filters } = queryKey[1];
      let is_internal = localStorage.getItem("is_internal_call");
      let is_internal_call;
      if (is_internal) {
        is_internal_call = is_internal;
      }

      return await getInvoice({
        sort,
        ...filters,
        ...(is_internal ? { is_internal_call } : {}),
      });
    },
    {
      retryOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const columns = useMemo(() => {
    let { columns } = columnMapper({
      columns: columnData,
      isExpanded: false,
      isSelection: activeKey === "0" ? true : false,
      isDownload: activeKey === "0" ? true : false,
      isPay: activeKey === "0" ? true : false,
      isReceipt: activeKey === "1" ? true : false,
      width: width - 300,
    });
    return columns;
  }, [columnData]);

  const isMobileView = window.innerWidth <= 600;
  const tabledata = useMemo(
    () => (isFetched && !isError && data?.data?.docs ? data.data.docs : []),
    [isFetched, data]
  );

  let tabledataLength = tabledata && tabledata.length;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageCount,
    state: { pageIndex, pageSize, sortBy, filters, columnResizing, expanded },
    prepareRow,
    toggleRowExpanded,
    setHiddenColumns,
    setColumnOrder,
    allColumns,
    visibleColumns,
    gotoPage,
    setPageSize,
    selectedFlatRows,
    toggleRowSelected,
    toggleAllRowsSelected,
  } = useTable(
    {
      columns,
      data: tabledata,
      manualFilters: true,
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
      getRowId: (row, relativeIndex, parent) => {
        // In row object you have access to data.
        // Yon choose parameter. In this example I used uniqueId

        return parent ? [parent.id, row._id].join(".") : row._id;
      },
    },
    useFilters,
    useSortBy,
    // useFlexLayout,
    useColumnOrder,
    useExpanded,
    usePagination,
    useRowSelect,
    useResizeColumns
  );
  useEffect(() => {
    let rowIds = Object.keys(expanded);

    if (rowIds.length > 1) {
      toggleRowExpanded(
        rowIds.filter((e) => e == expandedRowId.current),
        false
      );
    }

    expandedRowId.current = rowIds[0];
  }, [expanded]);
  useEffect(() => {
    let selectedRowId = selectedFlatRows.map((i) => i.original._id);
    let existingRowId = selectedInvoice.map((i) => i._id);

    let unique1 = selectedRowId.filter((o) => existingRowId.indexOf(o) === -1);
    let unique2 = existingRowId.filter((o) => selectedRowId.indexOf(o) === -1);
    const unique = unique1.concat(unique2);
    if (unique && unique.length > 0 && isFooter) {
      dispatch(getSelectedInvoice(selectedFlatRows));
    }
  }, [selectedFlatRows, modalState]);

  useEffect(() => {
    if (selectedFlatRows && selectedFlatRows.length === 0) {
      // Mapping the Required Data
      let finalData = {};
      const mappingTableDatas = tabledata.map((e) => {
        if (finalData[e.invoice_currency]) {
          finalData[e.invoice_currency] = [
            ...finalData[e.invoice_currency],
            e._id,
          ];
        } else {
          finalData[e.invoice_currency] = [e._id];
        }

        return {
          _id: e._id,
          invoiceCurrency: e.invoice_currency,
          invoiceTotalAmount:
            e.invoice_total_amount && e.invoice_total_amount.value,
        };
      });

      let hCurrency = Object.keys(finalData).reduce(
        (acc, curr) => {
          if (finalData[curr].length > acc.count) {
            return {
              currency: curr,
              count: finalData[curr].length,
              invId: finalData[curr],
            };
          }
          return acc;
        },
        { count: 0 }
      );

      if (hCurrency && hCurrency.invId) {
        hCurrency.invId.map((e) => {
          toggleRowSelected(e);
        });
      }
    }
  }, [isFetched, activeKey, data]);
  let totalAmtLength = total_outstanding_amount;
  const widths = window.innerWidth;

  const [isOpen, setIsOpen] = useState(false);
  const PromisePopUp = useQuery(
    "ModalPopUp",
    async () => {
      return await popUp();
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      isFetchedAfterMount: false,
      isFetched: false,
      refetchInactive: false,
    }
  );

  useEffect(() => {
    if (
      _.get(PromisePopUp, "data.data.doc.is_promise_pending", false) &&
      modalState &&
      !debtor_rights_modal
    ) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
    // if (customer.e_sign_id) {
    //   dispatch(setESignModal(true));
    // }
  }, [PromisePopUp.data?.data?.doc?.is_promise_pending, debtor_rights_modal]);

  useEffect(() => {
    if (customer && customer.is_pending_sign && !debtor_rights_modal) {
      dispatch(setESignModal(true));
    }
  }, [customer]);

  //Detailed Invoice In Phone View

  let findClient = _.get(isInvoiceDetails, "business_unit", "")
    ? referenceData &&
      referenceData["business_unit_list"].find(
        (e) => e.id === _.get(isInvoiceDetails, "business_unit", "")
      )
    : [];
  const filteredCOlumnMObileData = columnData.filter(
    (item) =>
      item.accessor !== "business_unit" && item.accessor !== "invoice_number"
  );
  const sortedColumnData = filteredCOlumnMObileData.sort(
    (a, b) => a.order - b.order
  );
  //Render Based on accessor and sorted array based on the customize label in Labe
  // Added the Custom Field from  the Organization
  const renderInvoiceDetails = sortedColumnData.map((column) => {
    const dueDaysText =
      column.accessor === "invoice_date" &&
      isInvoiceDetails?.status === "due" ? (
        <div
          style={{ color: "#FBBD04" }}
        >{`(in ${isInvoiceDetails?.inv_due_days} days)`}</div>
      ) : column.accessor === "invoice_date" &&
        isInvoiceDetails?.status === "overdue" ? (
        <div
          style={{ color: "#FD372A" }}
        >{`(by ${isInvoiceDetails?.inv_due_days} days)`}</div>
      ) : (
        ""
      );

    const value = _.get(isInvoiceDetails, column.accessor, null);
    if (value !== null) {
      let formattedValue;

      if (column.accessor.startsWith("custom_field.")) {
        const customFieldKey = column?.accessor?.replace("custom_field.", "");
        const customField = getCustomInvoiceFields(organization).find(
          (field) => field.path === customFieldKey
        );

        if (customField) {
          switch (customField.data_type) {
            case "DATE":
              formattedValue = format.date({ isOrg: true, value });
              break;
            case "HYPERLINK":
              formattedValue = (
                <a
                  href={value}
                  target="_blank"
                  style={{
                    color: "blue",
                    textDecoration: "underline",
                    fontSize: "14px",
                  }}
                  rel="noopener noreferrer"
                >
                  {value}
                </a>
              );
              break;
            case "AMOUNT":
              formattedValue = format.currency({
                amount: _.get(value, "value", ""),
                currency: _.get(value, "currency", ""),
                styleCurrency: {
                  color: "#333860",
                  width: "180px",
                  fontWeight: "500",
                  textAlign: "end",
                  fontSize: "14px",
                  lineHeight: "17px",
                },
              });
              break;
            default:
              formattedValue = value;
          }
        }
      } else if (column.accessor === "status") {
        formattedValue = (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              gap: "5px",
            }}
          >
            <div
              style={{
                width: "8px",
                height: "8px",
                borderRadius: "50px",
                backgroundColor:
                  value === "overdue"
                    ? "#FD372A"
                    : value === "due"
                    ? "#FBBD04"
                    : "",
              }}
            />{" "}
            {value}
          </div>
        );
      } else {
        if (column.rd_name) {
          switch (column.rd_name) {
            default:
              formattedValue = format.rd({
                id: value,
                name: column.rd_name,
              });
          }
        } else if (column.cell_type) {
          switch (column.cell_type) {
            case "DATEORGTZ":
              formattedValue = (
                <div
                  style={{ display: "flex", justifyContent: "end", gap: "5px" }}
                >
                  {`${format.date({
                    isOrg: true,
                    value: value,
                  })}`}
                  <>{dueDaysText}</>{" "}
                </div>
              );
              break;
            case "CURRENCY":
              formattedValue = format.currency({
                amount: _.get(value, "value", ""),
                currency: _.get(value, "currency", ""),
                styleCurrency: {
                  color: "#333860",
                  width: "180px",
                  fontWeight: "500",
                  textAlign: "end",
                  fontSize: "14px",
                  lineHeight: "17px",
                },
              });
              break;
            case "USER":
              formattedValue = format.rd({
                id: value,
                name: column.rd_name,
              });
            case "ALPHANUMERIC":
              formattedValue = value;
            default:
              formattedValue = _.capitalize(value);
          }
        }
      }

      return (
        <DetailsComponent
          keyValue={column?.literal_id}
          value={formattedValue}
          cellType={column.cell_type}
          // valueColor={
          //   column.accessor === "status" && value === "overdue"
          //     ? "#FD372A"
          //     : value === "due"
          //     ? "#FBBD04"
          //     : ""
          // }
        />
      );
    }
    return null;
  });
  useEffect(() => () => setFooter(true), []);
  return (
    <>
      <div className="invoices-container" style={{ position: "relative" }}>
        <div className="invoices-header-container">
          <div className="invoices-header___message-conatiner">
            <div>
              {/* <HeadingXLarge>{customerData?.name}</HeadingXLarge> */}
              <div className="sidebar-top">
                <NavLink
                  to={`/${hash}${showActiveUser ? `?i=${showActiveUser}` : ""}`}
                >
                  <div
                    style={{
                      textAlign: "left",
                      width: "100%",
                      paddingLeft: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      {widths <= 500 ? (
                        <Avatar
                          overrides={{
                            Avatar: {
                              style: ({ $theme }) => ({
                                borderTopLeftRadius: $theme.borders.radius100,
                                borderTopRightRadius: $theme.borders.radius100,
                                borderBottomRightRadius:
                                  $theme.borders.radius100,
                                borderBottomLeftRadius:
                                  $theme.borders.radius100,
                              }),
                            },
                            Root: {
                              style: ({ $theme }) => ({
                                borderTopLeftRadius: $theme.borders.radius100,
                                borderTopRightRadius: $theme.borders.radius100,
                                borderBottomRightRadius:
                                  $theme.borders.radius100,
                                borderBottomLeftRadius:
                                  $theme.borders.radius100,
                                backgroundColor: "#F8F8FB",
                                width: "48px",
                              }),
                            },
                            Initials: {
                              style: () => ({
                                color: default_color,
                                fontSize: "14px",
                                fontWeight: "700",
                                //  objectFit:'contain'
                              }),
                            },
                            Avatar: {
                              style: () => ({
                                objectFit: "contain",
                              }),
                            },
                          }}
                          name={organizationData?.name}
                          src={
                            organizationData &&
                            organizationData.customer_portal_ui &&
                            organizationData.customer_portal_ui.logo
                              ? organizationData.customer_portal_ui.logo
                              : null
                          }
                          size="scale1200"
                        />
                      ) : organizationData &&
                        organizationData.customer_portal_ui &&
                        organizationData.customer_portal_ui.logo ? (
                        <div
                          style={{
                            maxWidth: `95px`,
                            minWidth: `18px`,
                            maxHeight: `48px`,
                          }}
                        >
                          <img
                            src={
                              organizationData &&
                              organizationData.customer_portal_ui &&
                              organizationData.customer_portal_ui.logo
                                ? organizationData.customer_portal_ui.logo
                                : null
                            }
                            alt="customer_Logo"
                            className="customer_portal_logo"
                          />
                        </div>
                      ) : (
                        <Avatar
                          overrides={{
                            Avatar: {
                              style: ({ $theme }) => ({
                                borderTopLeftRadius: $theme.borders.radius100,
                                borderTopRightRadius: $theme.borders.radius100,
                                borderBottomRightRadius:
                                  $theme.borders.radius100,
                                borderBottomLeftRadius:
                                  $theme.borders.radius100,
                              }),
                            },
                            Root: {
                              style: ({ $theme }) => ({
                                borderTopLeftRadius: $theme.borders.radius100,
                                borderTopRightRadius: $theme.borders.radius100,
                                borderBottomRightRadius:
                                  $theme.borders.radius100,
                                borderBottomLeftRadius:
                                  $theme.borders.radius100,
                                backgroundColor: "#F8F8FB",
                                width: "48px",
                                objectFit: "contain",
                              }),
                            },
                            Initials: {
                              style: () => ({
                                color: default_color,
                                fontSize: "14px",
                                fontWeight: "700",
                                objectFit: "contain",
                              }),
                            },
                            Avatar: {
                              style: () => ({
                                objectFit: "contain",
                                width: "100px",
                              }),
                            },
                          }}
                          name={organizationData?.name}
                          src={
                            organizationData &&
                            organizationData.customer_portal_ui &&
                            organizationData.customer_portal_ui.logo
                              ? organizationData.customer_portal_ui.logo
                              : null
                          }
                          // size="scale1200"
                        />
                      )}

                      <div
                        // style={{
                        //   display: "flex",
                        //   flexDirection: "column",
                        //   width: "100%",
                        // }}
                        className="org-name"
                      >
                        <div>
                          <div
                            className="org_name"
                            $style={{ color: "#333860" }}
                          >
                            {organizationData?.name}
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              color: "#787878",
                              display: "flex",
                              gap: "2px",
                              // hap: "10px",
                              flexDirection: `${
                                totalAmtLength &&
                                totalAmtLength.toString().length > 15
                                  ? "column"
                                  : "row"
                              }`,
                            }}
                          >
                            <div className="totl-ots-mob"> Total O/S: </div>
                            <div className="totl_ots-web">
                              {" "}
                              Total Outstanding:{" "}
                            </div>
                            <NumericFormat
                              displayType="text"
                              value={
                                total_outstanding_amount
                                  ? total_outstanding_amount
                                  : 0
                              }
                              thousandsGroupStyle={currencyGroupStyle}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              thousandSeparator={currencySeparator}
                              decimalSeparator={currencyDecimalSeparator}
                              renderText={(values) => (
                                <div style={{ paddingLeft: "3px" }}>
                                  {currencyList[currency]
                                    ? currencyList[currency]
                                    : currency}{" "}
                                  {values}
                                </div>
                              )}
                            />
                          </div>
                          <div></div>
                        </div>
                        {isMobileView && is_document_authorize_configured && (
                          <div className="document-download_button">
                            <NavLink to={`/${hash}/authorize/Document`}>
                              <TextButton
                                kind={KIND.tertiary}
                                size={SIZE.compact}
                                // endEnhancer={
                                //   <i className="mx-icon-view-less font-icon-color" />
                                // }
                              >
                                Download
                              </TextButton>
                            </NavLink>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
            <div className="invoices-header___nav-buttons">
              {accessValue?.is_mini_miranda_configured && (
                <div>
                  <div
                    class="button-tilt"
                    onClick={() => {
                      dispatch(setDebtorRightsModal(true));
                    }}
                  >
                    <IconButton kind={KIND.tertiary}>
                      <DebtorRight size={18} />
                      <span class="label-hidden">Consumer Rights</span>
                    </IconButton>
                  </div>
                </div>
              )}

              <div>
                <div
                  class="button-tilt email-btn"
                  onClick={() => {
                    sendEmail();
                  }}
                >
                  <IconButton kind={KIND.tertiary}>
                    <i className="mx-icon-Vector-2" />
                    <span class="label-hidden">Email us</span>
                  </IconButton>
                </div>
              </div>

              {accessValue && accessValue.is_callback && (
                <div>
                  <NavLink
                    to={`/${hash}/requesting-calls${
                      showActiveUser ? `?i=${showActiveUser}` : ""
                    }`}
                  >
                    <div class="button-tilt">
                      <IconButton kind={KIND.tertiary}>
                        <i className="mx-icon-call-back-2" />
                        <span class="label-hidden">Call Back Request</span>
                      </IconButton>
                    </div>
                  </NavLink>
                </div>
              )}
            </div>
          </div>

          {/* Phone View Invoice Details */}

          {!isFooter && isInvoiceDetails && (
            <div
              className="pay-installment-new-container"
              style={{
                padding: "25px",
                // minHeight: "768px",
              }}
            >
              <div
                className="header-pay-container"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <LabelLarge
                    $style={{
                      fontSize: "16px",
                      lineHeight: "16px",
                      fontWeight: "600",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      width: "171px",
                    }}
                  >
                    {/* {_.get(isInvoiceDetails, "invoice_number", "-")} */}
                    {RowInvoiceNumber?.length
                      ? RowInvoiceNumber
                      : clientRef?.length
                      ? clientRef
                      : customerId?.length
                      ? customerId
                      : "-"}
                  </LabelLarge>
                  <div
                    onClick={() => {
                      setFooter(true);
                    }}
                  >
                    <CrosssSvg />
                  </div>
                </div>
                <LabelLarge
                  $style={{
                    fontSize: "14px",
                    lineHeight: "17px",
                    // paddingTop: "10px",
                    fontWeight: "500",
                  }}
                >
                  {findClient && findClient.label ? findClient.label : ""}{" "}
                </LabelLarge>
                <div style={{ border: "1px solid #EEEEEE" }}></div>
                <ParagraphLarge style={{ color: "#ADADAD" }}>
                  Details
                </ParagraphLarge>
                <div className="invoice-details-scroll">
                  {renderInvoiceDetails ? (
                    renderInvoiceDetails
                  ) : (
                    <ParagraphLarge
                      style={{ textAlign: "center", color: "#787878" }}
                    >
                      No Details Found
                    </ParagraphLarge>
                  )}
                </div>
              </div>
            </div>
          )}
          {isFooter && (
            <div>
              {PromisePopUp?.data?.data?.doc?.is_promise_pending &&
              isOpen &&
              modalState ? (
                <CustomerPortalModal
                  PromisePopUp={PromisePopUp}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                />
              ) : (
                ""
              )}
              {
                // customer.is_pending_sign
                customer.is_pending_sign &&
                !isOpen &&
                isEsign &&
                !isEsignStore ? (
                  <ESignModal />
                ) : (
                  <></>
                )
              }
              <Tabs
                overrides={{
                  Root: {
                    style: ({ $isActive }) => ({
                      height: "calc(100vh - 126px)",
                      // height:
                      //   selectedFlatRows && selectedFlatRows.length > 0
                      //     ? "calc(100vh - 225px)"
                      //     : "calc(100vh - 126px)",
                      overflow: "hidden",
                    }),
                  },

                  TabBorder: {
                    style: ({ $isActive }) => ({
                      height: "2px",
                    }),
                  },
                  TabList: {
                    style: ({ $theme }) => ({}),
                  },
                  TabHighlight: {
                    style: ({}) => ({
                      borderRadius: "3px",
                      backgroundColor: default_color,
                    }),
                  },
                }}
                activeKey={activeKey}
                onChange={({ activeKey }) => {
                  setActiveKey(activeKey);
                  dispatch(tabMotionChange(activeKey));
                  if (activeKey === "1") {
                    setQueryFilter({ ...queryFilter, type: ["paid", "stl"] });
                  } else {
                    setQueryFilter({ ...queryFilter, type: [] });
                  }
                }}
                activateOnFocus
              >
                <Tab
                  title="Outstanding"
                  overrides={{
                    Tab: {
                      style: ({ $theme }) => ({
                        color: activeKey === "0" ? default_color : "#333860",
                      }),
                    },
                  }}
                >
                  <InvoiceTable
                    menuName={menuName}
                    visibleColumns={visibleColumns}
                    setInvoiceDetails={setInvoiceDetails}
                    isInvoiceDetails={isInvoiceDetails}
                    setFooter={setFooter}
                    updateQueryFilter={setQueryFilter}
                    getTableProps={getTableProps}
                    headerGroups={headerGroups}
                    getTableBodyProps={getTableBodyProps}
                    page={page}
                    prepareRow={prepareRow}
                    setWidth={setWidth}
                    selectedFlatRows={selectedFlatRows}
                    default_color={default_color}
                    queryFilter={queryFilter}
                    setQuerySortBy={setQuerySortBy}
                    activeKey={activeKey}
                    isLoading={isLoading}
                    querySortBy={querySortBy}
                    columnData={columnData}
                    tabledata={tabledata}
                    columnResizing={columnResizing}
                    showActiveUser={showActiveUser}
                    toggleAllRowsSelected={toggleAllRowsSelected}
                  />
                </Tab>
                {invoice && invoice.is_display_paid_invoices === false ? (
                  ""
                ) : (
                  <Tab
                    title={intl.formatMessage({ id: "paid_invoice" })}
                    overrides={{
                      Tab: {
                        style: ({ $theme }) => ({
                          color: activeKey === "1" ? default_color : "#333860",
                        }),
                      },
                    }}
                  >
                    <InvoiceTable
                      setFooter={setFooter}
                      menuName={menuName}
                      visibleColumns={visibleColumns}
                      setInvoiceDetails={setInvoiceDetails}
                      isInvoiceDetails={isInvoiceDetails}
                      updateQueryFilter={setQueryFilter}
                      getTableProps={getTableProps}
                      headerGroups={headerGroups}
                      getTableBodyProps={getTableBodyProps}
                      page={page}
                      prepareRow={prepareRow}
                      setWidth={setWidth}
                      selectedFlatRows={selectedFlatRows}
                      default_color={default_color}
                      queryFilter={queryFilter}
                      setQuerySortBy={setQuerySortBy}
                      activeKey={activeKey}
                      isLoading={isLoading}
                      querySortBy={querySortBy}
                      columnData={columnData}
                      tabledata={tabledata}
                      showActiveUser={showActiveUser}
                      columnResizing={columnResizing}
                      toggleAllRowsSelected={toggleAllRowsSelected}
                    />
                  </Tab>
                )}
              </Tabs>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Invoices;
